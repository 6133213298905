<template>
  <v-dialog
    v-model="isVisible"
    max-width="315px"
    :hide-overlay="true"
    :persistent="true"
    :scrollable="true"
  >
    <v-card class="pa-6 text-break">
      <v-card-title class="subtitle-2 text-break">
        {{ modal.title }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <p
              v-for="(paragraph, pIndex) in modal.text"
              :key="pIndex"
              class="caption font-weight-medium text-break"
            >
              {{ paragraph }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions
        v-if="modal.actions && modal.actions.length"
        class="mb-3"
      >
        <v-row class="px-6">
          <v-spacer />

          <v-btn
            v-for="(action, actionIndex) in modal.actions"
            :key="actionIndex"
            :class="action.classes"
            :text="action.isText"
            x-small
            @click="fireActionEvent(action.event)"
          >
            {{ action.text }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'FeatureModal',
  computed: {
    ...mapToolsState({
      modal: (state) => state.modal,
      modalbar: (state) => state.modalbar,
    }),
    isVisible: {
      get() {
        return this.modalbar
      },
      set(value) {
        this.$store.commit('tools/modalbar', value, { root: true })
      },
    },
  },
  methods: {
    fireActionEvent(eventName) {
      this.$store.dispatch(eventName)
    },
  },
}
</script>
